<template>
  <div class="vue-tempalte">
    <form>
      <h3>{{ this.title }}</h3>
      <br>

      <iframe v-if="jitsiCode[0] != '_'" :src="jitsiUrl" allow="camera; microphone; fullscreen; display-capture;" frameborder="0"></iframe>

      <input type="button" v-if="jitsiCode == '_wait'" @click="endCall()" :value="'End call'" class="btn btn-primar">

    </form>
  </div>
</template>


<script>
import {socialvue} from '@/config/pluginInit'
import api from "@/api/api";

export default {
  name: 'VideoCallCusPage',
  mounted() {
    socialvue.index();
  },
  data() {
    return {
      callId: this.$route.params.callId,
      jitsiCode: '_wait',
      clientName: '',
      displayName: '',

      refresh: null
    }
  },
  created() {
    this.startRefresh();
    this.waitCall();
  },
  beforeDestroy() {
    this.endRefresh();
  },
  methods: {
    startRefresh() {
      this.refresh = setInterval(this.waitCall, 2000);
    },
    endRefresh() {
      if (this.refresh != null) {
        clearInterval(this.refresh);
        this.refresh = null;
      }
    },
    waitCall() {
      api
        .post('calls/waitCall', {
          token: this.token,
          call_id: this.callId
        })
        .then((response) => {
          this.clientName = response.data.call.to,
          this.displayName = response.data.call.from,
          this.jitsiCode = response.data.call.code
        });

      if (this.jitsiCode != '_wait'){
        this.endRefresh();
      }
    },
    endCall() {
      api
        .post('calls/denyCall', {
          token: this.token,
          call_id: this.callId
        })
        .then((response) => {
          this.jitsiCode = response.data.call.code
        });
    }
  },
  computed: {
    token() {
      return this.$store.getters["Setting/token"];
    },
    jitsiUrl() {
      return `https://meet.jit.si/${this.jitsiCode}#userInfo.displayName="${this.displayName}"&config.prejoinPageEnabled=false`
    },
    title() {
      if (this.jitsiCode == '_wait') {
        return `Calling ${this.clientName}...`;
      }
      if (this.jitsiCode == '_deny') {
        return 'Call ended';
      }

      return `Call with ${this.clientName}`;
    }
  }
};
</script>

<style scoped>
input[type="button"] {
  background-color: #ef476f;
  color: white;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}
iframe {
  width: 100%;
  height: 60vh;
}
</style>
